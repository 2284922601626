@media screen and (max-width: 800px) {
  .image-slider {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    position: relative;
  }

  .image-slider-button-box {
    position: absolute;
    z-index: 05;
    top: 77%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }

  .image-slider-button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    align-items: center;
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    border: none;
    background-color: rgba(0, 0, 0, 0.589);
    color: white;
    margin: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .search-input-homepage-box {
    margin-top: 30px;
  }

  .product-card-box {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .card {
    overflow: visible;
    display: flex;
    width: 40%;
    height: 165px;
    margin: 15px;
    cursor: pointer;
  }

  .input {
    width: 150px;
  }

  .input:focus {
    width: 200px;
  }

  .footer {
    height: max-content;
    padding-bottom: 10px;
  }

  .footer-top {
    flex-direction: column;
  }

  .footer-top-part {
    width: 100%;
    position: relative;
  }

  .footer-bottom {
    width: 80%;
    margin: auto;
    margin-top: 10px;
    font-size: 10px;
  }

  .card-menu {
    width: 90%;
    min-width: 300px;
    margin: 8px;
    margin-bottom: 5px;
    transition: none;
  }

  .ourmenu-cart-section {
    display: none;
  }

  .ourmenu-right-section {
    width: 100%;
  }

  .ourmenu-left-section {
    position: absolute;
    width: 280px;
    z-index: 10;
    height: 100%;
    background-color: white;
    box-shadow: none;
    border-right: 1px solid rgb(180, 180, 180);
    border-top: 1px solid rgb(180, 180, 180);
    top: 0px;
  }

  .ourmenu-page {
    position: relative;
  }

  .card-menu-1 {
    width: 98%;
    height: 100%;
    flex-direction: row !important;
  }

  .menu-image-box-1 {
    width: 60% !important;
    flex-direction: column !important;
    height: max-content;
  }

  .card-menu-image {
    margin-left: 5px;
    width: 30vw;
  }

  .card-menu-image-1 {
    margin-left: 5px;
    width: 30vw;
  }

  .quantity-box {
    margin-bottom: 0px;
    margin-top: 5px;
  }

  .menu-image-box {
    justify-content: space-evenly;
    width: 33% !important;
    margin-top: 0;
  }

  .quantity {
    justify-content: flex-start;
  }

  .menu-card-title-name {
    text-align: start !important;
  }

  .menu-card-detail {
    text-align: start !important;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    padding-right: 15px;
  }

  .products-right-small {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
}

  .menu-card-price {
    text-align: start !important;
  }

  .card-menu:hover {
    scale: 1;
  }

  .favourite-add {
    font-size: 15px;
    color: rgb(51, 51, 51);
  }

  .favourite-delete {
    font-size: 15px;
  }

  .front .front-content {
    padding: 0;
  }

  .products-left {
    width: 33%;
    gap: 8px;
  }

  .products-right {
    width: 67%;
    font-size: 14px;
    flex-direction: column;
    align-items: flex-start;
  }

  .displey-none {
    display: none;
  }

  .menu-open {
    display: block;
  }

  .logout-modal {
    width: 90%;
    top: 30%;
    transform: translate(-50%, -60%);
    max-width: 300px;
    font-size: 15px;
  }

  .form-container {
    padding: 1rem;
    padding-bottom: 0;
  }

  .master-container {
    width: 96%;
    padding: 8px;
  }

  .cartpage-mastar-container {
    width: 92%;
    padding: 10px;
    padding-top: 0;
  }

  .myaccountpage {
    flex-direction: column;
    height: 100%;
    width: 99%;
    /* height: 800px; */
  }

  .myaccountpage-left {
    width: 100%;
    height: max-content;
  }

  .myaccountpage-right {
    width: 100%;
    height: max-content;
  }

  .pi-main-box {
    width: 100%;
  }

  .fs-13 {
    font-size: 13px;
  }

  .product-category {
    font-size: 15px;
  }

  .address-box {
    width: 65%;
    height: max-content;
    margin: auto;
    margin-top: 20px;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 55px;
    background-color: #f3f3f3;
    position: relative;
    transition: all;
    transition-duration: 400ms;
  }

  .addaddres-bottom_box {
    margin: 0px 0px;
  }

  .adress-delete-edit {
    position: absolute;
    top: 36%;
    right: 10px;
    font-size: 19px;
    display: flex;
    gap: 19px;
    /* flex-direction: column; */
    cursor: pointer;
  }

  .height {
    height: 500px !important;
  }

  .ordertxt-1 {
    font-size: 12px;
    margin: 0;
    font-weight: 600;
  }

  .ordertxt-2 {
    font-size: 12px;
    margin: 0;
  }

  .ordertxt-3 {
    font-size: 12px;
    margin: 0px;
    font-weight: 600;
  }

  .order-box-top {
    display: flex;
    width: 96%;
    justify-content: space-between;
    margin: 0px 5px;
  }

  .price {
    font-size: 12px !important;
  }

  .card-footer {
    font-size: 11px;
  }

  .title {
    font-size: 14px;
  }

  .sign {
    padding: 0.5rem;
    font-size: 13px;
    width: 60%;
    height: 20px;
    margin: auto;
  }

  .sign-no {
    padding: 0.5rem;
    font-size: 13px;
    width: 60%;
    height: 20px;
    margin: auto;
  }

  .catogory-box {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .cart-page {
    flex-direction: column;
  }

  .cartpage-left-section {
    width: 100%;
    /* display: none; */
  }

  .cartpage-right-section {
    width: 100%;
  }

  .cartpage-service-options-1 {
    width: 93%;
  }

  .dis-fl-fl-co {
    height: max-content;
  }

  .dis-fl-fl-co p {
    line-height: 18px;
  }

  .cartpage-service-options {
    width: 95%;
    margin-bottom: 10px;
    height: 40px;
    padding: 5px 10px;
  }

  .cartpage-service-options input {
    margin: 0;
    margin-right: 8px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .service-type-paragraph {
    margin: 0px 0px 0px 0px;
    font-size: 10px;
  }

  .cartpage-service-options-2 {
    width: 43%;
    height: 40px;
  }

  .f-14 {
    font-size: 12px !important;
  }

  .f-15 {
    font-size: 13px !important;
  }

  .title p {
    width: 100%;
    padding: 0;
    text-align: left;
  }

  .menu-card-title-name {
    padding-right: 35px;
  }

  .cartpage-service-options-box-1 {
    padding: 20px 10px;
  }

  .cartpage-right-section-2 {
    z-index: 99;
    padding: 15px;
    width: 93%;
    margin-top: 10px;
    box-shadow: none;
    padding-bottom: 5px;
    position: fixed;
    bottom: 50px;
    border-radius: 0;
    border-top: 1px solid rgb(199 199 199);

  }

  .cartpage-right-section-1 {
    padding: 15px;
    width: 85%;
    margin-top: 10px;
    padding-bottom: 5px;
  }

  .cartpage-right-section-3 {
    z-index: 100;
    padding: 15px;
    width: 93%;
    margin-top: 10px;
    box-shadow: none;
    padding-bottom: 5px;
    position: fixed;
    bottom: 5%;
    /* bottom:  10%; */
    border-radius: 0;
    border-top: 1px solid rgb(199 199 199);
  }

  .cartpage-right-section-2 button {
    /* position: absolute;
    right: 10px;
    top: 25px; */
    padding: 8px;
    font-size: 12px;
  }

  .mycart-table-order-row select {
    width: 170px;
  }

  .mycart-table-order-row {
    width: 100%;
  }

  .cartpage-service-options-box {
    margin-top: 5px;
    padding-bottom: 0;
  }

  .mycart-table-order {
    padding-bottom: 0;
  }

  .cartoage-input-box {
    flex-direction: row;
    align-items: center;
    margin-left: 0px;
  }

  .cartpage-service-options-2:hover {
    scale: 1;
  }

  .cartpage-service-options:hover {
    scale: 1;
  }

  .cartpage-service-options-1:hover {
    scale: 1;
  }

  .cartpage-service-options-1:hover {
    scale: 1;
  }

  .f-12 {
    font-size: 11px !important;
    margin: 0;
  }

  .cartpage-service-options-1 input {
    margin-right: 10px;
  }

  .account-modal {
    width: 100% !important;
    height: max-content;
    bottom: 50px;
    transform: none;
    left: 0;
    top: auto;
  }

  .button-container-bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 101;
    border-radius: 0;
  }

  .button-bottom-nav {
    outline: 0 !important;
    border: 0 !important;
    width: 25%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }

  .icon-bottom-nav {
    font-size: 20px;
    height: 20 px;
    width: 40px;
  }

  .scroll-in-mobile {
    height: 81vh;
    overflow: scroll;
  }

  .displaynone {
    display: block;
  }

  .myordercart-mobile {
    display: block;
    width: 100%;
    height: max-content;
    position: fixed;
    bottom: 50px;
    background-color: rgb(255, 255, 255);
    z-index: 10;
  }

  .myordercart-mobile-1 {
    border-bottom: 1px solid rgb(126, 126, 126);
    border-top: 1px solid rgb(126, 126, 126);
    background: #e7e7e7;
    height: max-content;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    flex-direction: column;
  }

  .myordercart-mobile-2 {
    height: 45px;
    border-bottom: 1px solid rgb(126, 126, 126);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0 127 0);
    color: white;
    font-size: 13px;
    padding: 0px 20px;
  }

  .myordercart-mobile-1 div {
    font-size: 11px;
    color: rgb(48, 48, 48);
    font-weight: 600;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .myordercart-mobile-1 p {
    font-size: 12px;
    color: rgb(48, 48, 48);
    font-weight: 600;
  }

  .myordercart-mobile-1 p span {
    font-size: 12px;
    margin-left: 5px;
    color: rgb(87, 87, 87);
  }

  .myordercart-mobile-section-2 {
    width: 100%;
    padding-bottom: 15px;
    position: fixed;
    bottom: 50px;
    background-color: rgba(17, 24, 39, 1);
    z-index: 10;
  }

  .myordercart-mobile-section-3 {
    width: 100%;
    height: 145px;
    position: fixed;
    bottom: 50px;
    background-color: rgba(17, 24, 39, 1);
    z-index: 10;
    padding-top: 15px;
  }

  .xmark-ms-2 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: rgb(255, 255, 255);
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    /* box-shadow: 0px 0px 25px #8d8d8d; */
  }

  .xmark-ms-2 i {
    font-size: 18px;
  }

  .mobile-cart-button-box {
    display: flex;
    justify-content: space-evenly;
  }

  .mobile-cart-button {
    width: 40%;
    color: rgba(17, 24, 39, 1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7px 5px;
    border-radius: 8px;
    height: 30px;
    background-color: rgba(167, 139, 250, 1);
  }

  .myordercart-mobile-section-3 h4 {
    margin: 0;
    margin-bottom: 5px;
    margin-left: 22px;
    color: rgba(156, 163, 175, 1);
  }

  .myordercart-mobile-section-3 .f-13 {
    margin: 0;
    line-height: 17px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 12px;
    color: rgba(156, 163, 175, 1);
  }

  .myordercart-mobile-section-3 p {
    margin: 0;
    margin-bottom: 0px;
    font-size: 12px;
    color: rgba(17, 24, 39, 1);
  }

  .mycart-service-ty-pe-heading {
    margin: 0;
    margin-bottom: 15px;
    margin-left: 30px;
    color: rgba(156, 163, 175, 1);
    font-size: 17px;
    margin-top: 15px;
  }

  .mycart-service-ty-pe {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
  }

  .mycart-service-ty-pe-option {
    height: 50px;
    width: 88%;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-left: 12px;
    position: relative;
  }

  .mycart-service-ty-pe button {
    width: 91%;
    height: 40px;
    margin-top: 0px;
    background-color: rgba(167, 139, 250, 1);
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 14px;
  }

  .mycart-service-ty-pe-option p {
    margin: 0;
  }

  .f-16 {
    font-size: 14px !important;
  }

  .mycart-service-ty-pe-option input {
    position: absolute;
    right: 10px;
    width: 17px;
    height: 17px;
  }

  .main-modal-mothers-hut {
    width: 100%;
    bottom: 50px;
    left: 0;
    top: auto;
    transform: none;
    padding: 5px 0px 10px 0px;
  }

  .address-box-formodal {
    height: 115px;
    overflow: scroll;
  }

  .section-1-flex {
    display: flex;
    width: 95%;
    justify-content: space-evenly;
    font-size: 12px;
    padding-top: 10px;
  }

  .dis-non {
    display: none;
  }

  .mobile-cont-button-1 {
    width: 90%;
    height: 40px;
    margin-top: 5px;
    background-color: rgba(167, 139, 250, 1);
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 14px;
  }

  .product-category-head {
    font-size: 13px;
  }

  .making-div-cent {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .mobile-icon-back-1 {
    background-color: rgb(167 139 250);
    font-size: 14px;
    position: absolute;
    color: white;
    top: 15px;
    left: 20px;
    padding: 6px;
    border-radius: 50%;
  }

  .coupons form {
    padding: 5px;
  }

  .cart .quantity {
     align-items: center;
    height: 25px;
  }

  .cart .quantity label {
    width: 20px;
    height: 17px;
  }

  .cart .quantity button {
    width: 20px;
    height: 25px;
  }

  .quantity__input {
    width: 25px;
  }

  .main-modal-section-1 {
    padding: 10px 5px;
    font-size: 12px;
    width: 94%;
    flex-direction: column;
    align-items: center;
  }

  .main-modal-section-2 {
    font-size: 12px;
  }

  .main-modal-section-3 {
    font-size: 12px;
  }

  .main-modal-section-4 {
    font-size: 12px;
  }

  .select-k1 select {
    font-size: 12px;
  }

  .quantity__minus:hover,
  .quantity__plus:hover {
    background: #ededed;
    color: #000000;
  }

  .strong-1 {
    width: 100%;
    text-align: center;
    background: #000000b6;
    padding: 3px 15px;
    border-radius: 8px;
    font-size: 11px;
    font-weight: 100;
    position: absolute;
    bottom: 0;
  }

  strong {
    font-size: 10px;
    color: rgb(255, 255, 255);
  }

  .card-menu-favorite {
    position: absolute;
    top: 10px;
    font-size: 16px;
    right: 15px;
    bottom: auto;
  }

  .menu-card-title-name {
    width: 85%;
    font-size: 13px;
    padding: 0;
    margin-bottom: 2px;
  }

  .mobile-filter-page {
    display: block;
  }

  .footer-text-link {
    font-size: 13px;
    line-height: 15px;
  }

  .footer-text-header {
    font-size: 15px;
    padding-bottom: 5px;
  }

  .address-text {
    margin: 0;
    font-size: 12px;
    color: #383838;
  }


  .form-container-1 {
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  .form-container-2 {
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
  }

  .cart .products {
    padding: 4px 10px;
  }


  .fs-m {
    justify-content: flex-start;
    margin-left: 15px;
  }

  .inputBox_container {
    width: 60%;

  }
 
  .opencoupon-btn {
    padding: 8px 15px;
    width: 83%;
  }

  .address-flex-box {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 0px 0px;
    width: 92%;
    border: none;
  }




  .main-modal-button-1 {
    margin: auto;
    margin-top: 10px;
  }


  .address-flex-box div {
    display: flex;
    width: 47%;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .variableproduct-modal {
    width: 100%;
    transform: translate(-50%, 0%);
    top: auto;
    bottom: 50px;
    /*top: 30px;*/
  }

  .VP-heading {
    font-size: 16px;
  }

  .VP-heading-1 {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 0;

  }

  .VP-heading-2 {
    font-size: 13px;
    margin-bottom: 5px;

  }

  .mycart-empty-txt-box-1 .mycart-empty-image {
    width: 40%;
    margin-bottom: 30px
  }

  .products-cart-box-1 {
    width: 82%;
    padding-left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .dfjcc {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .section-1-flex div {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0px 4px;
  }

  .mycart-quantitybox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    align-items: center;
    padding: 0;
  }

  .mycart-productname {
    text-align: left;
    width: 100%;
  }

  .add-address-button {
    right: 10px;
    top: 10px;
  }

  .mobile-service-selection {
    font-size: 13px;
    position: absolute;
    left: 12%;
    background-color: white;
    border: none;
  }

  .card-cart {
    /* min-height: 490px; */
    min-height: auto;
  }

  .checkout .checkout--footer {
    padding: 0px 7px 0px 12px;
  }

  .cartpage-right-section-2 button:hover {
    scale: 1.00;
  }

  .address-box:hover {
    scale: 1.00;
  }

  .order-box-top-top {
    margin: 7px 3px;
  }

  .toastDiv {
    position: fixed;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 999;
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .ourMenuColor {
    background-color: rgb(241, 241, 241);
    /* Change this to your desired color */

  }

  .myCartColor {
    background-color: rgb(241, 241, 241);
    /* Change this to your desired color */

  }

  .defaultColor {
    background-color: rgb(241, 241, 241);
    /* Change this to your desired color */

  }

  .image-for-big {
    border-radius: 8px;
    width: 90px;
  }

  .products-cart-box-1 {
    width: 70%;
    padding-left: 0px;
    display: flex;
    align-items: center;
  }

  .quantityintosalepricemycart {
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: space-between;
    color: #515151;
  }

  .mycart-itemcontainer {
    max-height: max-content;
    overflow: scroll;
  }

  .map-modal {
    width: 90%;
  }

  .vp-modal-content {
    height: max-content;
    padding: 15px 10px;
    overflow: scroll;
    scrollbar-width: none;
    height: 400px;
  }

  .Variable_productname{
    width: 45%;
  }

}